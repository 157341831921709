import { Component } from "react";
import React from "react";
import { Link } from "react-router-dom";

class Products extends Component {
	constructor(props) {
		super(props);
		this.prodAr = [];
		this.state = {
			data: [],
		};
		this.jsonUrl = "https://listproducts.artefaktas.workers.dev/";
		this.style = {
			marginTop: "2rem",
			marginBottom: "2rem",
			backgroundColor: "#f0f0f0",
			color: "#212121",
			textDecoration: "none",
			boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
		};
		this.containerStyle = {
			minHeight: "544px",
		};
		this.rowStyle = {
			marginBottom: "2rem",
			paddingTop: "2rem",
			paddingBottom: "2rem",
		};
		this.cardStyle = {
			paddingTop: "1rem",
			width: "18rem",
		};
		this.cardImgStyle = {
			maxHeight: "14rem",
		};
	}

	componentDidMount() {
		fetch(this.jsonUrl)
			.then((d) => d.json())
			.then((r) => {
				this.setState({ data: r[0][0] });
			});
	}

	render() {
		return (
			<div>
				<div className="container" style={this.containerStyle}>
					<div className="row" style={this.rowStyle}>
						{this.state.data.map((element) => {
							let description, itemid, price, title, firstPhoto;
							itemid = element[0]["itemid"];
							title = element[0]["title"];
							description = element[0]["description"];
							price = element[0]["price"];
							firstPhoto = element[0]["photos"][0][0];

							return (
								<div
									className="card mx-auto mt-2 mb-2"
									style={this.cardStyle}
									key={itemid}
								>
									<img
										src={firstPhoto}
										key={element[0]["photos"][0]}
										style={this.cardImgStyle}
										className="card-img-top"
										alt="..."
									/>
									<div className="card-body" key={itemid}>
										<h5 className="card-title">{title}</h5>
										<p className="card-text">{title}</p>
										<p className="card-text">{description}</p>
										<p className="card-text">{`Kaina: ${price}`}</p>
										<Link
											className="btn btn-md btn-primary pp"
											onClick={this.initPp}
											to={`/products/${itemid}`}
											key={itemid}
										>
											Atidaryti
										</Link>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}
export default Products;
