import { Component } from "react";
import ReturnAlert from "./noitemalert";
import Swal from "sweetalert2";
import React from "react";

class PpButton extends Component {
	constructor(props) {
		super(props);
		this.ppUrl = "https://prodanswer.artefaktas.workers.dev";
		this.shipList = "https://shiplist.artefaktas.workers.dev/";
		this.pputUrl = "https://orderputter.artefaktas.workers.dev/";
		this.pillStyle = {
			maxWidth: "400px",
			minHeight: "148px",
			display: "none",
		};

		this.stateOb = {
			passedOptions: [],
			id: this.props.id,
			name: this.props.name,
			description: this.props.description,
			price: this.props.price,
			shipData: [],
			amount: this.props.amount,
			quanta: this.props.quant,
		};

		this.state = {
			status: false,
			amount: null,
			q: this.props.quant,
		};
	}

	returnQuantity = () => {
		return this.stateOb.quanta;
	};

	switchCheck = (prop) => {
		let passedamount = prop;
		if (passedamount > 0 && passedamount !== undefined) {
			return <div id="pbutton" style={this.pillStyle}></div>;
		} 
		else if(passedamount === undefined && passedamount!==0){
			return <p>Loading</p>
		}
		else{
			return <ReturnAlert />;
		}
	};

	componentDidMount() {
		let quantity = this.returnQuantity();
		fetch(this.shipList)
			.then((list) => list.json())
			.then((parsedList) => {
				let passedOptions = this.stateOb.passedOptions;
				this.stateOb.shipData = parsedList[0][0];
				this.stateOb.shipData.forEach((element, index) => {
					let ob = {
						id: element[0]["id"],
						label: element[0]["label"],
						type: element[0]["type"],
						selected: false,
						amount: {
							value: String(parseFloat(element[0]["price"], 2)),
							currency_code: "EUR",
						},
					};
					const newItems = [...passedOptions];
					newItems[index] = ob;
					passedOptions = newItems;
				});
				passedOptions[0]["selected"] = true;
				this.stateOb.passedOptions = passedOptions;
			});

		let fetchPaypal = () => {
			const passedname = this.props.name;
			const passeddescription = this.props.description;
			let passedprice = this.props.price;
			const putUrl = this.pputUrl;
			let orId = this.stateOb.id;
			let ppoptions = this.stateOb.passedOptions;
			try {
				window.paypal
					.Buttons({
						createOrder: function (data, actions) {
							passedprice = quantity * parseFloat(passedprice, 2);
							return actions.order.create({
								purchase_units: [
									{
										description: passeddescription,
										name: passedname,
										amount: {
											value: String(passedprice),
										},
										breakdown: {
											item_total: {
												value: String(passedprice),
											},
										},
										shipping: {
											options: ppoptions,
										},
									},
								],
							});
						},
						onApprove: function (data, actions) {
							return actions.order.capture().then(function (details) {
								let sendInfo = {
									orderId: orId,
									id: details.id,
									status: details.status,
									payerEmail: details.payer.email_address,
									bought_amount: quantity,
									item_description: details.purchase_units[0].description,
									paid_price: details.purchase_units[0].amount.value,
									shipping_paid:
										details.purchase_units[0].shipping.options[0].amount.value,
									shipping_method:
										details.purchase_units[0].shipping.options[0].label,
									name: details.payer.name.given_name,
									last_name: details.payer.name.surname,
									full_name: details.purchase_units[0].shipping.name.full_name,
									address_line_1:
										details.purchase_units[0].shipping.address.address_line_1,
									admin_area1:
										details.purchase_units[0].shipping.address.admin_area_1,
									admin_area2:
										details.purchase_units[0].shipping.address.admin_area_2,
									country_code:
										details.purchase_units[0].shipping.address.country_code,
									postal_code:
										details.purchase_units[0].shipping.address.postal_code,
								};

								fetch(putUrl, {
									method: "POST",
									body: JSON.stringify(sendInfo, null, 2),
								}).then(() => {
									Swal.fire({
										position: "top-end",
										icon: "success",
										title: "Užsakymas gautas!",
										showConfirmButton: false,
										timer: 2000,
									})
										.then(() => {
											console.log("Successfull payment received");
										})
										.then(() => {
											Swal.fire(
												"Dėmesio",
												`Netrukus susisieksiu su tavimi elektroniniu paštu užsakymo patikslinimui. Prekės bus pristatomos tavo pasirinktu būdu: <b>${details.purchase_units[0].shipping.options[0].label}</b>`,
												"success"
											).then((result) => {
												if (result.isConfirmed) {
													Swal.fire(
														"Šaunu!",
														"O dabar atnaujinu šį puslapį tam, kad matytum likusį prekių kiekį",
														"success"
													).then((result) => {
														if (result.isConfirmed) {
															window.location.reload(false);
														}
													});
												}
											});
										});
								});
							});
						},
						onShippingChange: function (data, actions) {
							let shippingPrice;
							let prodPrice;
							let allSum;

							if (data.shipping_address.country_code === "US") {
								shippingPrice = parseFloat(
									data.selected_shipping_option.amount.value,
									2
								);
								prodPrice = parseFloat(passedprice, 2);
								allSum = shippingPrice + prodPrice;
							}

							return actions.order.patch([
								{
									op: "replace",
									path: "/purchase_units/@reference_id=='default'/amount",
									value: {
										currency_code: "EUR",
										value: String(allSum),
										breakdown: {
											item_total: {
												currency_code: "EUR",
												value: prodPrice,
											},
											shipping: {
												currency_code: "EUR",
												value: shippingPrice,
											},
										},
									},
								},
							]);
						},
						onError: function (e) {
							console.log(e);
						},
						style: {
							color: "blue",
							shape: "rect",
						},
					})
					.render("#pbutton")
					.then((s) => {
						console.log("Suc_asx1sa2x1");
						document.querySelector("#pbutton").style.display = "block";
					})
					.catch((e) => {
						console.log(e);
					});
			} catch (error) {
				console.log(error);
			}
		};

		let ppInject = async () => {
			await fetch(this.ppUrl)
				.then((d) => d.json())
				.then((r) => {
					let d = r[0];
					const s = document.createElement("script");
					s.src = d[0];
					s.defer = "defer";
					s.addEventListener("load", () => {
						this.setState({ status: true });
						if (this.state.status === true) {
							setTimeout(() => {
								fetchPaypal();
							}, 300);
						}
					});
					document.body.appendChild(s);
				});
		};
		ppInject();
	}

	render() {
		return this.switchCheck(this.props.amount);
	}
}

export default PpButton;
