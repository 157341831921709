import { Component } from "react";
import React from "react";

class ReturnAlert extends Component {
	constructor(props) {
		super(props);
		this.message = "Atsiprašau, bet šios prekės prekyboje nebėra.";
		this.alertStyle = {
			maxWidth: "400px",
		};
	}

	render() {
		return (
			<div className="alert alert-danger" style={this.alertStyle}>
				{this.message}
			</div>
		);
	}
}

export default ReturnAlert;
