import { Component } from "react";
import React from "react";

class Header extends Component {
	constructor(props) {
		super(props);
		this.message = "Sveiki atvykę į E-Parduotuvę";
		this.style = {
			marginTop: "2rem",
			marginBottom: "2rem",
			backgroundColor: "#212121",
			color: "#fff",
			boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
		};
		this.rowStyle = {
			paddingTop: "2rem",
			paddingBottom: "2rem",
		};
	}

	render() {
		return (
			<div className="container" style={this.style}>
				<div className="row" style={this.rowStyle}>
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<h2>{this.message}</h2>
					</div>
				</div>
			</div>
		);
	}
}
export default Header;
