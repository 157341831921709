import { Component } from "react";
import React from "react";
import Ppbutton from "./button";

class Product extends Component {
	constructor(props) {
		super(props);

		this.message = "Hello";
		this.state = {
			renderData: [],
			photoArray: [],
			loaded: false,
			passedAmount: 1,
			defaultMin: 1,
		};
		this.workerUrl = "https://prodgetter.artefaktas.workers.dev/";
		this.style = {
			marginTop: "2rem",
			marginBottom: "2rem",
			backgroundColor: "#f0f0f0",
			color: "#212121",
			textDecoration: "none",
			boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
			minHeight: "461.38px",
		};
		this.rowStyle = {
			marginBottom: "2rem",
			paddingTop: "2rem",
			paddingBottom: "2rem",
		};
		this.id = this.props.match.params["id"];
		this.imgStyle = {
			maxWidth: "46%",
			marginLeft: "2%",
			marginRight: "2%",
			marginTop: "2rem",
			marginBottom: "2rem",
		};
		this.headingStyle = {
			marginBottom: "2rem",
		};
		this.colStyle = {
			marginTop: "2rem",
			marginBotton: "2rem",
		};
		this.amountStyle = {
			marginTop: "2rem",
			marginBottom: "2rem",
			maxWidth: "400px",
		};
	}

	handleStateChange = (ev) => {
		this.setState({ passedAmount: ev });
	};

	returnAmount = () => {
		return this.state.passedAmount;
	};

	componentDidMount() {
		fetch(this.workerUrl, {
			method: "POST",
			body: this.id,
		})
			.then((d) => d.json())
			.then((r) => {
				this.setState({ renderData: r[0][0][0][0] });
				this.setState({ photoArray: r[0][0][0][0]["photos"][0] });
			})
			.catch((e) => {});
	}

	render() {
		return (
			<div className="container" style={this.style}>
				<div className="row" style={this.rowStyle}>
					<div
						className="<div col-lg-6 col-md-6 col-sm-12 col-xs-12>"
						style={this.colStyle}
					>
						{this.state.photoArray.map((el) => {
							return (
								<a
									href={el}
									key={el}
									data-lightbox={`${this.id}_product_picture_artefaktas`}
									data-title={`product_${this.id}_picture_artefaktas.eu`}
								>
									<img
										key={el}
										src={el}
										alt={`${this.id}_product_picture_artefaktas`}
										className="img-responsive"
										style={this.imgStyle}
									/>
								</a>
							);
						})}
					</div>

					<div
						className="<div col-lg-6 col-md-6 col-sm-12 col-xs-12>"
						style={this.colStyle}
					>
						<h1 style={this.headingStyle}>{this.state.renderData["title"]}</h1>
						<p>{this.state.renderData["description"]}</p>
						<p>{`Kaina: ${this.state.renderData["price"]}`}</p>
						<p>{`Likęs kiekis: ${this.state.renderData["amount"]}`}</p>

						<input
							id="sync"
							style={this.amountStyle}
							defaultValue={this.state.defaultMin}
							min="1"
							max={this.state.renderData["amount"]}
							type="number"
							className="form-control form-control-md"
							onKeyDown={(event) => {
								let key = event.key;
								if (!key.match(/^\d+$/) && key !== "Backspace") {
									event.preventDefault();
								}
							}}
							onMouseDown={() => {
								let key = parseInt(document.querySelector("#sync").value);
								let max = this.state.renderData["amount"];
								if (key === 0) {
									document.querySelector("#sync").value = 1;
									this.handleStateChange(1);
								} else if (key > 0 && key <= max) {
									this.handleStateChange(key);
								} else if (key > max) {
									document.querySelector("#sync").value = max;
									this.handleStateChange(max);
								}
							}}
							onKeyUp={() => {
								let key = parseInt(document.querySelector("#sync").value);
								let max = this.state.renderData["amount"];
								if (key === 0) {
									document.querySelector("#sync").value = 1;

									this.handleStateChange(1);
								} else if (key > 0 && key <= max) {
									this.handleStateChange(key);
								} else if (key > max) {
									document.querySelector("#sync").value = max;
									this.handleStateChange(max);
								}
							}}
						/>
						<Ppbutton
							id={this.id}
							price={this.state.renderData["price"]}
							name={this.state.renderData["price"]}
							description={this.state.renderData["description"]}
							amount={this.state.renderData["amount"]}
							quant={this.returnAmount()}
							key={this.returnAmount()}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Product;
