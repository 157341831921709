import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import Header from "./components/header";
import Products from "./components/products";
import Product from "./components/product";
import Footer from "./components/footer";
import NotFound from "./components/notfound";
import "lightbox2/dist/js/lightbox";
import "lightbox2/dist/css/lightbox.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

ReactDOM.render(
	<Router>
		<Switch>
			<Route exact path="/" component={Header} />
			<Route exact path="/products" component={Header} />
			<Route exact path="/products/:id" component={Header} />
			<Route
				render={(props) => (
					<NotFound
						message={
							<iframe
								title="Are you a hacker or something?"
								src="https://giphy.com/embed/YQitE4YNQNahy"
								width="480"
								height="270"
								frameBorder="0"
								class="giphy-embed"
								allowFullScreen
							></iframe>
						}
						{...props}
					/>
				)}
			/>
		</Switch>
	</Router>,
	document.getElementById("header")
);

ReactDOM.render(
	<Router>
		<Switch>
			<Route exact path="/" component={Products} />
			<Route exact path="/products" component={Products} />
			<Route exact path="/products/:id" component={Product} />
			<Route
				render={(props) => (
					<NotFound
						message="Oi, 404: Toks kelias nerastas. Gal esi hakeris? "
						{...props}
					/>
				)}
			/>
		</Switch>
	</Router>,
	document.getElementById("products")
);

ReactDOM.render(
	<Router>
		<Switch>
			<Route exact path="/" component={Footer} />
			<Route exact path="/products" component={Footer} />
			<Route exact path="/products/:id" component={Footer} />
			<Route render={(props) => <NotFound {...props} />} />
		</Switch>
	</Router>,
	document.getElementById("footer")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
