import { Component } from "react";
import React from "react";

class NotFound extends Component {
	constructor(props) {
		super(props);
		this.style = {
			backgroundColor: "#212121",
			color: "white",
			height: "33.3vh",
		};

		this.state = {
			compMessage: "",
		};
	}

	componentDidMount() {
		if (this.props.message !== undefined) {
			this.setState({ compMessage: this.props.message });
		}
	}

	render() {
		return (
			<div className="container-fluid d-block">
				<div
					className="row text-center align-items-center justify-content-center"
					style={this.style}
				>
					<h1>{this.state.compMessage}</h1>
				</div>
			</div>
		);
	}
}

export default NotFound;
