import { Component } from "react";
import React from "react";

class Footer extends Component {
	constructor(props) {
		super(props);
		this.style = {
			backgroundColor: "#212121",
			color: "#fff",
		};
		this.address = {
			street: "Panevezys, Lithuania",
		};
		this.contacts = {
			email: "yadayada@artefaktas.eu",
			mobile: "yadayadayadya",
		};
		this.rowStyle = {
			paddingTop: "2rem",
			paddingBottom: "2rem",
		};
		this.social = {
			instagram: "https://www.instagram.com/_artefaktas_/",
			facebook: "https://www.facebook.com/artefaktasblog",
		};
	}

	render() {
		return (
			<div className="container" style={this.style}>
				<div className="row" style={this.rowStyle}>
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
						<p>Adresas: {this.address.street}</p>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
						<p>E.paštas: {this.contacts.email}</p>
						<p>Mobilusis: {this.contacts.mobile}</p>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
						<p>Socialinių tinklų profiliai</p>
						<p>
							<a href={this.social.facebook}>
								<i className="bi bi-facebook"></i> Facebook
							</a>
						</p>
						<p>
							<a href={this.social.instagram}>
								<i className="bi bi-instagram"></i> Instagram
							</a>
						</p>
					</div>
				</div>
			</div>
		);
	}
}
export default Footer;
